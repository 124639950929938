import React from 'react';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

type LoadingSpinnerProps = {
  size?: number;
};

export const LoadingSpinner = ({ size = 24 }: LoadingSpinnerProps) => {
  return (
    <Container>
      <CircularProgress size={size} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
