import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import styled from 'styled-components';

import { ReactComponent as WarningMark } from 'assets/svg/warning-mark_circles.svg';

import Text from 'components/Text';
import DialogModal from 'components/Modal/Dialog';
import { ErrorPayload } from 'types';

type CancelModalProps = {
  actionText: string;
  documentId: number;
  onClose: () => void;
  title: string;
  text: string;
  mutation: UseMutationResult<
    {},
    ErrorPayload,
    {
      id: number;
    }
  >;
};

export const CancelModal = ({
  actionText,
  documentId,
  onClose,
  text,
  title,
  mutation,
}: CancelModalProps) => {
  const { t } = useTranslation();

  return (
    <DialogModal
      open
      title={title}
      onClose={onClose}
      actionButton={() => mutation.mutate({ id: documentId })}
      withButtons
      actionButtonText={actionText}
      cancelButtonText={t('globaly.close')}
      actionLoading={mutation.isLoading}
      fullWidth
    >
      <div>
        <IconContainer>
          <WarningMark />
        </IconContainer>
        <TextContainer>
          <Text type="medium">{text}</Text>
        </TextContainer>
      </div>
    </DialogModal>
  );
};

const IconContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 26px;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  padding-inline: 12%;
  text-align: center;
`;
