import React, { useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

import { ReactComponent as MagnifierIcon } from 'assets/svg/actions_circle/magnifier.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/actions_circle/x.svg';
import { ReactComponent as ExportIcon } from 'assets/svg/export-icon_circle.svg';

import { getPeriod, toFormattedDate } from 'utils/date';
import { ErrorPayload, PaymentDocumentStatus, PaymentDocumentType } from 'types';
import { useExport } from 'containers/Payroll/hooks/useExport';
import { CancelModal } from 'containers/Payroll/components/CancelModal';
import { useError } from 'hooks/useError';
import useMutationCustom from 'hooks/useMutationCustom';

type ActionsProps = {
  row: GridRowParams['row'];
};

export const Actions = ({ row }: ActionsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { onError } = useError();

  const documentType = row.payment_document_type.id as PaymentDocumentType;

  const { exportDocument } = useExport({ id: row.id, documentType });

  const [openModal, setOpenModal] = useState(false);

  const config = useMemo(() => {
    switch (documentType) {
      case PaymentDocumentType.REGULAR_PAYROLL:
        return {
          view: () => history.push(`/payroll/${row.id}`, { step: 3 }),
          endpoint: '/payment_document/cancel',
          text: t('payroll.modal.cancel.title.regular_payroll', {
            period: getPeriod(row.period_start, row.period_end),
          }),
          invalidateQueries: ['payment_documents', 'month_data'],
        };
      case PaymentDocumentType.ADVANCE_PAYMENT:
        return {
          view: () => history.push(`/advance_payment_document/${row.id}`),
          endpoint: '/advance_payment_document/cancel',
          text: t('payroll.modal.cancel.title.advance_payment', {
            period: toFormattedDate(row.payment_date, 'd MMM, yyyy'),
          }),
          invalidateQueries: ['payment_documents'],
        };
      default:
        return {
          view: undefined,
          endpoint: '',
          text: '',
          invalidateQueries: [],
        };
    }
  }, [documentType, history, row.id, row.payment_date, row.period_end, row.period_start, t]);

  const mutation = useMutationCustom<{}, ErrorPayload, { id: number }>(
    ['cancel_payment_document'],
    {
      endpoint: config.endpoint,
      options: { method: 'post' },
    },
    {
      onSuccess: () => {
        addToast(t('payroll.modal.cancel.toast'), {
          appearance: 'success',
          autoDismiss: true,
        });
        queryClient.invalidateQueries(config.invalidateQueries);
        setOpenModal(false);
      },
      onError,
    }
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <GridActionsCellItem
          className={'actionButton'}
          icon={
            <Tooltip title={t('timeOff.view')} disableInteractive arrow>
              <StyledActionIcon as={MagnifierIcon} />
            </Tooltip>
          }
          label={t('timeOff.view')}
          onClick={config.view}
          sx={{
            padding: '2px',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        />
        <GridActionsCellItem
          className={'actionButton'}
          icon={
            <Tooltip title={t('components.dataGrid.export')} disableInteractive arrow>
              <StyledActionIcon as={ExportIcon} />
            </Tooltip>
          }
          label={t('components.dataGrid.export')}
          onClick={() => exportDocument()}
          sx={{
            padding: '2px',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        />
        {row.payment_document_status.id !== PaymentDocumentStatus.CANCELLED && (
          <GridActionsCellItem
            className={'actionButton'}
            icon={
              <Tooltip title={t('globaly.lowercase_cancel')} disableInteractive arrow>
                <StyledActionIcon as={CloseIcon} />
              </Tooltip>
            }
            label={t('globaly.lowercase_cancel')}
            onClick={() => setOpenModal(true)}
            sx={{ padding: '2px', '&:hover': { backgroundColor: 'transparent' } }}
          />
        )}
      </div>
      {openModal && (
        <CancelModal
          actionText={t('payroll.modal.cancel.action')}
          documentId={row.id}
          mutation={mutation}
          onClose={() => setOpenModal(false)}
          text={config.text}
          title={t('payroll.modal.cancel.name')}
        />
      )}
    </>
  );
};

const StyledActionIcon = styled.svg`
  width: 22px;
  height: 22px;
  circle {
    fill: var(--white);
  }
  path:not(#Vector, #Vector-2) {
    fill: var(--black);
  }

  &:hover {
    circle {
      fill: #396;
    }
    path:not(#Vector, #Vector-2) {
      fill: var(--white);
    }
    #Vector,
    #Vector-2 {
      stroke: var(--white);
    }
  }
`;
