import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridColumns,
  GridFilterModel,
  gridFilterModelSelector,
  GridInitialState,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { uniq, uniqBy } from 'lodash';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as MessageIcon } from 'assets/svg/message.svg';

import DataGrid from 'components/DataLists/DataGrid';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import useQueryCustom from 'hooks/useQueryCustom';
import {
  AdditionalEarningsAssignmentDocumentPayStatus,
  AdditionalEarningsAssignmentDocuments,
  ErrorPayload,
} from 'types';
import { toFormattedDate } from 'utils/date';
import { currencyFormatter } from 'utils/number';
import { Section } from 'containers/Payroll/components/Section';
import { Actions } from './Actions';

type SelectOption = {
  id: string | number;
  name: string;
};

type Filters = {
  earnings: SelectOption[];
  types: SelectOption[];
  statuses: SelectOption[];
};

type CustomToolbarProps = {
  filters: Filters;
};

const initialState: GridInitialState = {
  sorting: {
    sortModel: [
      { field: 'status', sort: 'asc' },
      { field: 'effective_period', sort: 'desc' },
    ],
  },
};

const statusOrder: Record<AdditionalEarningsAssignmentDocumentPayStatus, string> = {
  [AdditionalEarningsAssignmentDocumentPayStatus.PLANNED]: '1',
  [AdditionalEarningsAssignmentDocumentPayStatus.CURRENT]: '2',
  [AdditionalEarningsAssignmentDocumentPayStatus.INACTIVE]: '3',
  [AdditionalEarningsAssignmentDocumentPayStatus.CANCELLED]: '4',
};

const CustomToolbar = ({ filters }: CustomToolbarProps) => {
  const apiRef = useGridApiContext();
  const gridFilterModelItems: GridFilterModel['items'] = gridFilterModelSelector(apiRef).items;

  const typeSelectValue = useMemo(() => {
    const filterGridValue = gridFilterModelItems.find((e) => e.columnField === 'type')?.value;
    const result: SelectOption =
      filters.types.find((e) => e.id === filterGridValue) || filters.types[0];
    return result;
  }, [filters.types, gridFilterModelItems]);

  const earningSelectValue = useMemo(() => {
    const filterGridValue = gridFilterModelItems.find((e) => e.columnField === 'earning')?.value;
    const result: SelectOption =
      filters.earnings.find((e) => e.id === filterGridValue) || filters.earnings[0];
    return result;
  }, [filters.earnings, gridFilterModelItems]);

  const statusSelectValue = useMemo(() => {
    const filterGridValue = gridFilterModelItems.find((e) => e.columnField === 'status')?.value;
    const result: SelectOption =
      filters.statuses.find((e) => e.id === filterGridValue) || filters.statuses[0];
    return result;
  }, [filters.statuses, gridFilterModelItems]);

  const onChangeFilter = (value: SelectOption, type: 'type' | 'status' | 'earning') => {
    if (value.id) {
      apiRef.current.upsertFilterItem({
        columnField: type,
        value: value.id,
        operatorValue: 'equals',
        id: `fltr_${type}`,
      });
    } else {
      apiRef.current.deleteFilterItem({ columnField: type, id: `fltr_${type}` });
    }
  };

  return (
    <FiltersContainer>
      <SelectDropdown
        size={'small'}
        value={earningSelectValue}
        onChange={(_, value) => onChangeFilter(value as SelectOption, 'earning')}
        disableClearable
        options={filters.earnings}
        sx={{ width: 200 }}
      />
      <SelectDropdown
        size={'small'}
        value={typeSelectValue}
        onChange={(_, value) => onChangeFilter(value as SelectOption, 'type')}
        disableClearable
        options={filters.types}
        sx={{ width: 220 }}
      />
      <SelectDropdown
        size={'small'}
        value={statusSelectValue}
        onChange={(_, value) => onChangeFilter(value as SelectOption, 'status')}
        disableClearable
        options={filters.statuses}
        sx={{ width: 150 }}
      />
    </FiltersContainer>
  );
};

export const AdditionalEarnings = () => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<Filters>({
    earnings: [{ id: 0, name: t('payroll.history_block.select.earning') }],
    types: [{ id: 0, name: t('payroll.history_block.select.type') }],
    statuses: [{ id: 0, name: t('payroll.history_block.select.status') }],
  });

  const { data, isLoading } = useQueryCustom<AdditionalEarningsAssignmentDocuments, ErrorPayload>(
    ['additional_earnings_assignments'],
    {
      endpoint: '/additional_earnings_assignment_document',
      options: {
        method: 'get',
      },
    },
    {
      onSuccess: (data) => {
        const types = uniqBy(
          data.map((e) => e.additional_earning_type),
          'id'
        );
        const statuses = uniqBy(
          data.map((e) => e.additional_earnings_assignment_document_pay_status),
          'id'
        );
        const earnings = uniq(data.map((e) => e.additional_earning));
        setFilters((prev) => ({
          types: [
            prev.types[0],
            ...types.map(({ id, name }) => ({
              id,
              name,
            })),
          ],
          earnings: [
            prev.earnings[0],
            ...earnings.map((name) => ({
              id: name,
              name,
            })),
          ],
          statuses: [
            prev.statuses[0],
            ...statuses.map(({ id, name }) => ({
              id: statusOrder[id],
              name,
            })),
          ],
        }));
      },
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'effective_period',
        headerName: t('payroll.additional_earnings_assignment.form.effective_date.name'),
        valueFormatter: ({ value }) => toFormattedDate(value, 'd MMM, yyyy'),
        type: 'date',
        flex: 1,
      },
      {
        field: 'earning',
        headerName: t('payroll.additional_earnings_assignment.earning'),
        valueGetter: ({ row }) => row.additional_earning,
        flex: 1,
      },
      {
        field: 'type',
        headerName: t('globaly.type'),
        renderCell: ({ row }) => row.additional_earning_type.name,
        valueGetter: ({ row }) => row.additional_earning_type.id,
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('globaly.status'),
        renderCell: ({ row }) => (
          <Status data-status={row.additional_earnings_assignment_document_pay_status.id}>
            <span className="dot"></span>
            {row.additional_earnings_assignment_document_pay_status.name}
          </Status>
        ),
        valueGetter: ({ row }) =>
          statusOrder[
            row.additional_earnings_assignment_document_pay_status
              .id as AdditionalEarningsAssignmentDocumentPayStatus
          ],
        flex: 1,
      },
      {
        field: 'end_date',
        headerName: t('payroll.additional_earnings_assignment.form.end_date.name'),
        valueFormatter: ({ value }) => (value ? toFormattedDate(value, 'd MMM, yyyy') : '-'),
        type: 'date',
        flex: 1,
      },
      {
        field: 'net_amount',
        headerName: t('payroll.additional_earnings_assignment.net_amount'),
        valueGetter: ({ row, value }) =>
          currencyFormatter({ currency: row.currency, amount: value }),
        type: 'number',
        flex: 1,
      },
      {
        field: 'note',
        headerName: t('payroll.additional_earnings_assignment.form.note.name'),
        renderCell: ({ value }) =>
          !value ? (
            '-'
          ) : (
            <Tooltip title={value} enterDelay={200}>
              <StyledMessageIcon />
            </Tooltip>
          ),
        flex: 1,
      },
      {
        field: 'action',
        type: 'actions',
        headerName: t('globaly.action'),
        renderHeader: () => <></>,
        getActions: ({ row }) => {
          return [<Actions row={row} />];
        },
      },
    ],
    [t]
  );

  return (
    <Section style={{ height: '100%' }} title={t('payroll.tabs.additional_earnings')}>
      <DataGrid
        name={'additional_earnings'}
        saveGridState
        discardQueryStringSaveRestrict
        rows={data || []}
        columns={columns}
        initialState={initialState}
        loading={isLoading}
        disableQuickFilter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rowHeight={50}
        noRowsOverlayText={t('payroll.additional_earnings_assignment.no_additional_earning')}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: {
            filters,
          },
        }}
      />
    </Section>
  );
};

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  .MuiAutocomplete-inputRoot {
    height: 36px;
  }
  & > .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        & > div {
          button {
            padding: 3px 3px;
          }
        }
      }
    }
  }
`;

const Status = styled.div`
  color: var(--orange);
  .dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
    background: var(--orange);
  }

  &[data-status='current'] {
    color: var(--green);
    .dot {
      background: var(--green);
    }
  }
  &[data-status='cancelled'] {
    color: #bebebe;
    .dot {
      background: #bebebe;
    }
  }
  &[data-status='inactive'] {
    color: var(--black);
    .dot {
      background: var(--black);
    }
  }
`;

const StyledMessageIcon = styled(MessageIcon)`
  &:hover {
    & > path {
      fill: #396;
    }
  }
`;
