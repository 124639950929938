import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { ReactComponent as MagnifierIcon } from 'assets/svg/actions_circle/magnifier.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/actions_circle/x.svg';
import { CancelModal } from 'containers/Payroll/components/CancelModal';
import useMutationCustom from 'hooks/useMutationCustom';
import { ErrorPayload } from 'types';
import { useError } from 'hooks/useError';

type ActionsProps = {
  row: GridRowParams['row'];
};

export const Actions = ({ row }: ActionsProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { onError } = useError();

  const [openModal, setOpenModal] = useState(false);

  const mutation = useMutationCustom<{}, ErrorPayload, { id: number }>(
    ['cancel_payment_document'],
    {
      endpoint: '/additional_earnings_assignment_document/cancel',
      options: { method: 'post' },
    },
    {
      onSuccess: () => {
        addToast(t('payroll.additional_earnings_assignment.cancel_modal.toast'), {
          appearance: 'success',
          autoDismiss: true,
        });
        queryClient.invalidateQueries('additional_earnings_assignments');
        setOpenModal(false);
      },
      onError,
    }
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <GridActionsCellItem
          className={'actionButton'}
          icon={
            <Tooltip title={t('timeOff.view')} disableInteractive arrow>
              <StyledActionIcon as={MagnifierIcon} />
            </Tooltip>
          }
          label={t('timeOff.view')}
          onClick={() => history.push(`/additional_earnings_assignment_document/${row.id}`)}
          sx={{
            padding: '2px',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        />
        <GridActionsCellItem
          className={'actionButton'}
          icon={
            <Tooltip title={t('globaly.lowercase_cancel')} disableInteractive arrow>
              <StyledActionIcon as={CloseIcon} />
            </Tooltip>
          }
          label={t('globaly.lowercase_cancel')}
          onClick={() => setOpenModal(true)}
          sx={{ padding: '2px', '&:hover': { backgroundColor: 'transparent' } }}
        />
      </div>
      {openModal && (
        <CancelModal
          actionText={t('payroll.additional_earnings_assignment.cancel_modal.action')}
          documentId={row.id}
          mutation={mutation}
          onClose={() => setOpenModal(false)}
          text={t('payroll.additional_earnings_assignment.cancel_modal.text')}
          title={t('payroll.additional_earnings_assignment.cancel_modal.title')}
        />
      )}
    </>
  );
};

const StyledActionIcon = styled.svg`
  width: 22px;
  height: 22px;
  circle {
    fill: var(--white);
  }
  path:not(#Vector, #Vector-2) {
    fill: var(--black);
  }

  &:hover {
    circle {
      fill: #396;
    }
    path:not(#Vector, #Vector-2) {
      fill: var(--white);
    }
    #Vector,
    #Vector-2 {
      stroke: var(--white);
    }
  }
`;
